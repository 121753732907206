var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "add-communication",
        visible: _vm.isShow,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "818px",
        title: "选择沟通的职位",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "add-communication-header" }, [
        _c(
          "div",
          { staticClass: "search-box" },
          [
            _c("el-input", {
              staticClass: "search-keyword-input",
              attrs: { placeholder: "请输入职位或公司" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return (() => _vm.getJobList()).apply(null, arguments)
                },
              },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            }),
            _c(
              "span",
              {
                staticClass: "search-box-btn",
                on: { click: () => _vm.getJobList() },
              },
              [_vm._v("搜索")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "source-box" },
          [
            _c("span", [_vm._v("来源：")]),
            _c(
              "el-dropdown",
              {
                attrs: { trigger: "click" },
                on: { command: _vm.handleJobSource },
              },
              [
                _c("span", { staticClass: "source-select-link" }, [
                  _vm._v(
                    "\n                    " + _vm._s(_vm.sourceMap[_vm.source])
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "source-select-menu",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c("el-dropdown-item", { attrs: { command: 1 } }, [
                      _vm._v("内部职位"),
                    ]),
                    _c("el-dropdown-item", { attrs: { command: 3 } }, [
                      _vm._v("抢单A2A"),
                    ]),
                    _c("el-dropdown-item", { attrs: { command: 4 } }, [
                      _vm._v("抢单HR直招"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "job-list-wrapper",
        },
        [
          _vm.isLoading || (!_vm.isLoading && _vm.jobList.length)
            ? _c(
                "ul",
                { staticClass: "job-list" },
                _vm._l(_vm.jobList, function (job, index) {
                  return _c("li", { key: index, staticClass: "job-item" }, [
                    _c("div", { staticClass: "job-info" }, [
                      _c("div", { staticClass: "job-info-top" }, [
                        _c(
                          "a",
                          {
                            staticClass: "job-name",
                            attrs: {
                              href: `/#/Job/${job.id}`,
                              target: "_blank",
                              title: job.name,
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(job.name) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "job-tags" },
                          [
                            _vm._l(job.activityTags, function (item) {
                              return [
                                item.id == "JobEmergency"
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.id !== "StandstillJob" &&
                                              item.id !=
                                                "FirstOrderActivityJob",
                                            expression:
                                              "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                                          },
                                        ],
                                        key: item.id,
                                        attrs: {
                                          effect: "light",
                                          placement: "right",
                                          disabled: !item.description,
                                          "popper-class": "job-tag-description",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          attrs: { slot: "content" },
                                          domProps: {
                                            innerHTML: _vm._s(item.description),
                                          },
                                          slot: "content",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "job-detail-tag job-tag-emergency",
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: { href: "#icon-shandian" },
                                            }),
                                            _vm._v("急聘"),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "el-tooltip",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.id !== "StandstillJob" &&
                                              item.id !=
                                                "FirstOrderActivityJob",
                                            expression:
                                              "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                                          },
                                        ],
                                        key: item.id,
                                        attrs: {
                                          effect: "light",
                                          placement: "right",
                                          disabled: !item.description,
                                          "popper-class": "job-tag-description",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          attrs: { slot: "content" },
                                          domProps: {
                                            innerHTML: _vm._s(item.description),
                                          },
                                          slot: "content",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "job-detail-tag",
                                            class: `job-tag-${
                                              _vm.jobActivityTag[item.id].theme
                                            }`,
                                            attrs: { title: item.name },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                      ]
                                    ),
                              ]
                            }),
                            job.jobType == 3
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "job-detail-tag job-tag-info",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                HR\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            job.jobType == 5
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "job-detail-tag job-tag-info",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                代运营\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _c("div", { staticClass: "job-info-bottom" }, [
                        _c(
                          "span",
                          {
                            staticClass: "job-location",
                            attrs: { title: job.location },
                          },
                          [_vm._v("地点：" + _vm._s(job.location))]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "job-item-right" },
                      [
                        _c("div", { staticClass: "job-create" }, [
                          job.canViewFirmDetail
                            ? _c(
                                "a",
                                {
                                  staticClass: "job-customer",
                                  attrs: {
                                    href: `/#/Customer/${job.customerId}`,
                                    target: "_blank",
                                    title: job.firmShortName,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(job.firmShortName) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "job-customer normal",
                                  attrs: { title: job.firmShortName },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(job.firmShortName) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                          _c("span", { staticClass: "job-create-time" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(job.created) +
                                " 发布\n                        "
                            ),
                          ]),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "add-communication-btn",
                            attrs: {
                              type: "primary",
                              disabled: job.isCreateCommunication,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleAddCommunication(job.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  job.isCreateCommunication ? "已添加" : "添加"
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              )
            : _c("div", { staticClass: "page-empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@src/assets/images/jobList/empty-bg@2x.png"),
                  },
                }),
                _c("p", { staticClass: "des" }, [_vm._v("暂无数据")]),
              ]),
          _vm.jobList.length
            ? _c("el-pagination", {
                staticClass:
                  "pagination-common-new add-communication-pagination",
                attrs: {
                  "current-page": _vm.page.current,
                  "page-sizes": [10, 20, 50],
                  "page-size": _vm.page.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.page.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
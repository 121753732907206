<template>
    <el-dialog
        custom-class="add-communication"
        :visible.sync="isShow"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="818px"
        title="选择沟通的职位"
    >
        <div class="add-communication-header">
            <div class="search-box">
                <el-input
                    v-model="keyword"
                    placeholder="请输入职位或公司"
                    class="search-keyword-input"
                    @keydown.enter.native="() => getJobList()"
                >
                </el-input>
                <span class="search-box-btn" @click="() => getJobList()">搜索</span>
            </div>
            <div class="source-box">
                <span>来源：</span>
                <el-dropdown
                    trigger="click"
                    @command="handleJobSource">
                    <span class="source-select-link">
                        {{sourceMap[source]}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu
                        slot="dropdown"
                        class="source-select-menu">
                        <el-dropdown-item :command="1">内部职位</el-dropdown-item>
                        <el-dropdown-item :command="3">抢单A2A</el-dropdown-item>
                        <el-dropdown-item :command="4">抢单HR直招</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="job-list-wrapper" v-loading="isLoading">
            <ul
                class="job-list"
                v-if="isLoading || (!isLoading && jobList.length)"
            >
                <li
                    class="job-item"
                    v-for="(job, index) in jobList"
                    :key="index"
                >
                    <div class="job-info">
                        <div class="job-info-top">
                            <a
                                class="job-name"
                                :href="`/#/Job/${job.id}`"
                                target="_blank"
                                :title="job.name">
                                {{job.name}}
                            </a>
                            <div class="job-tags">
                                <template v-for="item in job.activityTags">
                                    <el-tooltip
                                        v-if="item.id == 'JobEmergency'"
                                        :key="item.id"
                                        effect="light"
                                        placement="right"
                                        :disabled="!item.description"
                                        v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                                        popper-class="job-tag-description">
                                        <div slot="content" v-html="item.description"></div>
                                        <span class="job-detail-tag job-tag-emergency"><font-icon href="#icon-shandian"></font-icon>急聘</span>
                                    </el-tooltip>
                                    <el-tooltip
                                        v-else
                                        :key="item.id"
                                        effect="light"
                                        placement="right"
                                        :disabled="!item.description"
                                        v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                                        popper-class="job-tag-description">
                                        <div slot="content" v-html="item.description"></div>
                                        <span class="job-detail-tag" :class="`job-tag-${jobActivityTag[item.id].theme}`" :title="item.name">{{item.name}}</span>
                                    </el-tooltip>
                                </template>
                                <span
                                    v-if="job.jobType == 3"
                                    class="job-detail-tag job-tag-info">
                                    HR
                                </span>
                                <span
                                    v-if="job.jobType == 5"
                                    class="job-detail-tag job-tag-info">
                                    代运营
                                </span>
                            </div>
                        </div>
                        <div class="job-info-bottom">
                            <span class="job-location" :title="job.location">地点：{{job.location}}</span>
                        </div>
                    </div>
                    <div class="job-item-right">
                        <div class="job-create">
                            <!-- :href="`/Headhunting/MyCompany.html#/Customer/${job.customerId}`" -->
                            <a
                                class="job-customer"
                                :href="`/#/Customer/${job.customerId}`"
                                target="_blank"
                                :title="job.firmShortName"
                                v-if="job.canViewFirmDetail">
                                {{job.firmShortName}}
                            </a>
                            <span
                                class="job-customer normal"
                                :title="job.firmShortName"
                                v-else>
                                {{job.firmShortName}}
                            </span>
                            <span class="job-create-time">
                                {{job.created}} 发布
                            </span>
                        </div>
                        <el-button
                            class="add-communication-btn"
                            type="primary"
                            :disabled="job.isCreateCommunication"
                            @click="handleAddCommunication(job.id)">
                            {{job.isCreateCommunication ? '已添加' : '添加'}}
                        </el-button>
                    </div>
                </li>
            </ul>
            <!-- <div class="empty-data" v-else>
                <span class="empty-img"></span>
                <p class="empty-text">暂无数据</p>
            </div> -->
            <div class="page-empty" v-else>
                <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
                <p class="des">暂无数据</p>
            </div>
            <el-pagination
                class="pagination-common-new add-communication-pagination"
                :current-page="page.current"
                :page-sizes="[10, 20, 50]"
                :page-size="page.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total"
                v-if="jobList.length"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
            <!-- <el-pagination
                class="pagination-common"
                :current-page="page.current"
                layout="total, prev, pager, next, slot"
                :total="page.total"
                v-if="jobList.length"
                @current-change="handleCurrentChange">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination> -->
        </div>
    </el-dialog>
</template>

<script>
import jobActivityTag from '#/js/config/jobActivityTag.json';
export default {
    data() {
        return {
            candidateId: "",
            resume: "",
            isShow: false,
            isLoading: false,
            keyword: "",
            source: 1,
            jobList: [],
            sourceMap: {
                1: "内部职位",
                2: "市场抢单",
                3: "抢单A2A",
                4: "抢单HR直招",
            },
            pagerJump: 0,
            page: {
                current: 1,
                size: 10,
                total: 0
            },
            jobActivityTag: jobActivityTag,
        }
    },
    methods: {
        show(resume) {
            this.resume = resume.detail || {};
            this.candidateId = resume.candidateId || "";
            this.isShow = true;
            this.getJobList();
        },
        getJobList(isSlient) {
            this.isLoading = true;
            let params = {};
            if(!isSlient) {
                this.page.current = 1;
            }
            params.start = (this.page.current - 1) * this.page.size;
            params.take = this.page.size;
            params.jobSource = this.source;
            params.keyword = this.keyword;
            params._ = new Date().getTime();
            _request({
                url: `/Candidate/${this.candidateId}/JobsForCommunication`,
                method: "GET",
                data: params
            }).then(res => {
                this.isLoading = false;
                this.page.total = res.total;
                if(res.list && res.list.length) {
                    this.jobList = res.list;
                } else {
                    this.jobList = [];
                }

                if(isSlient) {
                    let listEle = document.querySelector('.job-list-wrapper .job-list');
                    if(listEle) {
                        listEle.scrollTop = 0;
                    }
                }
            }).catch(err => {
                this.isLoading = false;
            })
        },
        handleJobSource(value) {
            this.source = value;
            this.getJobList();
        },
        handleSizeChange(val){
            this.page.size = val;
            this.page.current = 1;
            this.$nextTick(() => {
                this.getJobList(true);
            })
        },
        handleCurrentChange(val) {
            this.page.current = val;
            this.getJobList(true);
        },
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.page.total/this.page.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleCurrentChange(currentPager)
            }
        },
        handleAddCommunication(jobId) {
            this.isLoading = true;
            _request({
                url: `/Communication/Create`,
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                data: {
                    jobId: jobId,
                    candidateId: this.candidateId
                }
            }).then(res => {
                this.isLoading = false;
                shortTips('添加成功');
                this.getJobList();
                this.$emit('update-item', this.resume);
            }).catch(err => {
                this.isLoading = false;
            })
        },
        hideDialog() {
            this.isShow = false;
            this.keyword = "";
            this.source = 1;
            this.jobList = [];
        },
    }
}
</script>

<style lang="scss" scope>
    .add-communication.el-dialog {
        padding: 0;
        
        .add-communication-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 4px;

            .search-box {
                position: relative;
                display: flex;
                align-items: center;
                width: 590px;
                height: 38px;
                background: #38BC9D;
                border-radius: 6px;
                border: 2px solid #38BC9D;

                .search-keyword-input {
                    width: 515px;

                    .el-input__inner {
                        height: 34px;
                        line-height: 34px;
                        border: 0 none;
                    }
                }

                &-btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    color: #fff;
                    cursor: pointer;
                }
            }

            .source-box {
                .source-select-link {
                    color: $primary;
                    cursor: pointer;
                }
            }
        }
        
        .job-list-wrapper {
            margin-top: 15px;
            cursor: default;
        }
        .job-list {
            position: relative;
            min-height: 300px;
            max-height: 480px;
            // padding: 0 40px;
            margin: 0 -20px;
            padding: 0 20px;
            overflow: hidden;
            overflow-y: auto;
            border-bottom: 1px solid rgba(235,235,235,0.92);

            .job-item {
                display: flex;
                justify-content: space-between;
                padding: 26px 0 20px;
                line-height: 20px;
                border-top: solid 1px #ececec;
                // &:hover {
                //     background-color: #f6f6f6;
                // }

                //&:last-child {
                //    border: 0 none;
                //}

                &-right {
                    display: flex;
                    justify-content: space-between;
                    flex-shrink: 0;
                }
            }
            .job-info {
                width: 468px;
                margin-right: 20px;

                &-top {
                    display: flex;
                    align-items: center;
                    height: 20px;
                    font-size: 16px;
                }

                &-bottom {
                    display: flex;
                    align-items: center;
                    height: 20px;
                    margin-top: 8px;
                    font-size: 14px;

                    .job-location {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .job-name {
                    display: inline-block;
                    font-size: 16px;
                    color: #333;
                    font-weight: 600;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;

                    &:hover {
                        color: $primary;
                    }
                }

                .job-tags {
                    flex-shrink: 0;

                    .job-detail-tag{
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 6px;
                        height: 20px;
                        font-size: 12px;
                        color: #b9b9b9;
                        border: 1px solid #b9b9b9;
                        padding: 1px 6px;
                        border-radius: 3px;
                        vertical-align: top;
                        flex-shrink: 0;

                        &.job-tag-emergency {
                            color: #fff;
                            border-color: #FF4B4B;
                            background-color: #FF4B4B;
                        }

                        &.job-tag-danger{
                            color: #FF4B4B;
                            border-color: #FFB6B6;
                            background-color: rgba(255,75,75,0.1);;
                        }
                        &.job-tag-primary{
                            color: #fff;
                            border-color: #38bc9d;
                            color: #38bc9d;
                        }
                        &.job-tag-info{
                            color: #579BFF;
                            border-color: rgba(81,164,255,0.53);
                            background-color: rgba(185,208,255,0.14);
                        }
                        &.job-tag-warning{
                            color: #FF9E21;
                            border-color: #FFDDA8;
                            background-color: rgba(255,236,199,0.7);
                        }
                    }
                }
                
            }


            .job-create {
                width: 160px;
                margin-right: 20px;
                display: inline-flex;
                flex-direction: column;
                .job-customer {
                    color: #666;
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    margin-bottom: 2px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    &:hover {
                        color: $primary;
                    }

                    &.normal {
                        cursor: default;

                        &:hover {
                            color: #666;
                        }
                    }
                }
                .job-create-time {
                    font-size: 12px;
                    color: #999;
                }
            }
            .add-communication-btn {
                width: 90px;
                font-size: 14px;
                line-height: 32px;
                height: 34px;
                flex-shrink: 0;

                &.is-disabled {
                    opacity: 0.65;
                }
            }
        }
        .empty-data {
            margin: 10px 0 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                background-size: contain;
                width: 150px;
                height: 150px;
            }
            .empty-text {
                line-height: 20px;
                margin-bottom: 0px;
            }
        }

        .page-empty{
            width: 100%;
            padding-bottom: 30px;
            background: white;
            border-radius: 10px;
            text-align: center;
            >img{
                width: 158px;
                height: 178px;
                margin: 30px auto 0;
            }
            .des{
                margin: 24px auto 0;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 20px;
            }
        }

        .add-communication-pagination {
            padding-top: 14px;
            margin-bottom: -5px;
        }
    }
</style>

<style lang="scss">
.source-select-menu.el-dropdown-menu {
    margin-top: 0;
    padding: 0;
    .popper__arrow {
        display: none;
    }
}
</style>